<template>
  <v-app>
    <GlobalHeaderCheckout />
    <v-main>
      <slot />

      <ContentWrapper>
        <GlobalFooter />
      </ContentWrapper>
    </v-main>
  </v-app>
</template>
